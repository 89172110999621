import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { PageHeader, Banner } from '../components/utils'
import img from '../assets/images/travel.jpg'

import Posts from '../components/posts/Posts'

const TravelPage = ({data}) => (
  <Layout>
    <SEO title="Travel" />
    <PageHeader img={img}>
      <Banner title='' subtitle='To Travel is to Live...'>
      </Banner>
    </PageHeader>

    {/* { data &&  */}
      <Posts posts={data.allMarkdownRemark.edges}></Posts>
    {/* } */}
  </Layout>
)

export const pageQuery = graphql`
  query TravelQuery {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}, 
      filter: {
        frontmatter: {
        	category : {in: ["travel"]},
        	draft: {ne: true}
        }}) {
			edges {
        node {
          fields{
            slug
            readingTime {
              minutes
            }
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
						title
						cover {
							childImageSharp {
								fluid(maxWidth:512) {
									...GatsbyImageSharpFluid
								}
							}
						}
          }
        }
      }
    }
  }
`

export default TravelPage
